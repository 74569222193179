<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <div class="">
          <v-card class="elevation-1">
            <div class="tab-panel-">
              <v-tabs v-model="model" color="white" slider-color="primary">
                <v-tab
                  v-for="(tab, i) in tabItems"
                  :key="i"
                  :href="`#tab-${i}`"
                >
                  {{ tab }}
                </v-tab>
              </v-tabs>
            </div>
          </v-card>
        </div>
        <div
          class="dash-container info-tab-section"
          style="width: 100%; margin-top: 0"
        >
          <div v-if="model === 'tab-0'" class="mx-auto mt-0">
            <v-card-text class="py-0">
              <v-timeline align-top dense>
                <v-timeline-item color="pink" small>
                  <v-layout pt-3>
                    <v-flex xs1>
                      <strong>v. 2.0.12</strong>
                    </v-flex>

                    <v-flex xs12>
                      <div class="update-item">
                        <strong>My Profile</strong>
                        <div class="caption">
                          User can now view and update their personal info from
                          their profile.
                        </div>
                      </div>

                      <div class="update-item">
                        <strong>Class Teacher Attendance</strong>
                        <div class="caption">
                          Only the class teacher can view the attendance menu
                          now.
                        </div>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-timeline-item>

                <v-timeline-item color="pink" small>
                  <v-layout pt-3>
                    <v-flex xs1>
                      <strong>v. 2.0.11</strong>
                    </v-flex>
                    <v-flex xs12>
                      <div class="update-item">
                        <strong>Signature Upload</strong>
                        <div class="caption">
                          Principle, Exam Coordinator, Superintendent signature
                          upload from settings.
                        </div>
                      </div>
                      <div class="update-item">
                        <strong>Staff</strong>
                        <div class="caption">Staff Signature Image Upload.</div>
                      </div>
                      <div class="update-item">
                        <strong>Transport Manager</strong>
                        <div class="caption">
                          Manage Route, Fare, Destination for the students using
                          transportation facility.
                          <strong>Transportation > Manage Transport</strong>
                        </div>
                      </div>

                      <div class="update-item">
                        <strong>Attendance</strong>
                        <div class="caption">
                          Only the class teacher of a section is allowed to take
                          the attendance.
                        </div>
                        <div class="caption">Attendance UI Updated</div>
                      </div>

                      <div class="update-item">
                        <strong>Guardian's Student</strong>
                        <div class="caption">
                          The guardian list in
                          <strong>Members > Guardian</strong> now has a list of
                          their children name.
                        </div>
                      </div>

                      <div class="update-item">
                        <strong>Student's Optional Subject Assigner</strong>
                        <div class="caption">
                          <strong>Academic > Optional Group.</strong> Relate
                          Student's and their option subject.
                        </div>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-timeline-item>

                <v-timeline-item color="pink" small>
                  <v-layout pt-3>
                    <v-flex xs1>
                      <strong>v. 2.0.10</strong>
                    </v-flex>
                    <v-flex xs12>
                      <div class="update-item">
                        <strong>Section</strong>
                        <div class="caption">
                          Assign class teacher for section.
                        </div>
                      </div>
                      <div class="update-item">
                        <strong>Attendance</strong>
                        <div class="caption">
                          Restriction! Only class teacher can do the attendance.
                        </div>
                      </div>
                      <div class="update-item">
                        <strong>Permission Updated</strong>
                        <div class="caption">
                          Few missing permission were updated on section,
                          student profile etc.
                        </div>
                        <br />
                        <div class="caption">Bug Fixes on Promote student.</div>
                      </div>

                      <div class="update-item">
                        <strong>Demote Student</strong>
                        <div class="caption">
                          <strong>Student > Search > Profile > Enroll</strong>
                          has now a new option to demote a student.
                        </div>
                        <br />
                        <div class="caption">Bug Fixes on Promote student.</div>
                      </div>
                      <div class="update-item">
                        <strong>Subject's Teacher</strong>
                        <div class="caption">
                          Subject's teacher is now showing in subjects list.
                          Fetched according to the routine created.
                        </div>
                        <br />
                        <div class="caption">
                          Once the subject is selected subject's teacher is
                          selected by default on create assignment screen.
                          However the search can still be performed to choose
                          the teacher of user's choice.
                        </div>
                      </div>
                      <div class="update-item">
                        <strong>Statement Report (Billing)</strong>
                        <div class="caption">
                          Statement report for both transaction & advance is now
                          available on the billing section
                          <strong>Billing > Statement</strong>
                        </div>
                      </div>
                      <div class="update-item">
                        <strong>UI Updated (Billing)</strong>
                        <div class="caption">
                          Design tweaks on the billing section (fee, heads,
                          statement, offer etc)
                        </div>
                      </div>
                      <div class="update-item">
                        <strong>Members</strong>
                        <div class="caption">
                          DOB is now optional for guardian.
                        </div>
                        <br />
                        <div class="caption">
                          Guardian role now can be updated from the guardian
                          update screen.
                        </div>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-timeline-item>
                <v-timeline-item color="pink" small>
                  <v-layout pt-3>
                    <v-flex xs1>
                      <strong>v. 2.0.9</strong>
                    </v-flex>
                    <v-flex xs12>
                      <div class="update-item">
                        <strong>Guardian Update/Add</strong>
                        <div class="caption">Role Selection Bug Fix.</div>
                      </div>

                      <div class="update-item">
                        <strong>Guardian Update</strong>
                        <div class="caption">
                          <strong>(Member > Guardian > Update Guardian)</strong>
                          Now authorised users can update the guardian's role.
                        </div>
                      </div>
                      <div class="update-item">
                        <strong>Staff/Guardian Form Update</strong>
                        <div class="caption">
                          Gender first letter updated to Sentence case.
                        </div>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </div>

          <div v-if="model === 'tab-1'" class="payments">
            <payment></payment>
          </div>
          <v-layout row wrap v-if="model === 'tab-2'">
            <div class="settings flex xs12 mt-5">
              <!-- <v-container grid-list-md> -->
              <v-layout row wrap>
                <template v-for="(item, key) in items">
                  <v-flex xs6 :key="key">
                    <v-card height="100%" class="pa-2 setting-container">
                      <v-card-title class="subheading font-weight-bold">
                        {{ item.name }}
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-list dense>
                        <span v-if="item.list === 1">
                          <v-list-item>
                            <v-autocomplete
                              outlined
                              dense
                              :loading="principalLoading"
                              :search-input.sync="searchPrincipal"
                              :items="principals"
                              hide-no-data
                              class="pa-0"
                              label="Principle's signature"
                              v-model="principal"
                              style="width: 100%"
                            />
                          </v-list-item>
                          <v-list-item>
                            <v-autocomplete
                              outlined
                              dense
                              :search-input.sync="searchExamCoordinator"
                              :items="coordinators"
                              hide-no-data
                              label="Exam coordinator's Signature"
                              :loading="coordinatorLoading"
                              class="pa-0"
                              v-model="exam_coordinator"
                              style="width: 100%"
                            />
                          </v-list-item>
                          <v-list-item>
                            <v-autocomplete
                              outlined
                              dense
                              :search-input.sync="searchSuperitendent"
                              :items="superintendents"
                              hide-no-data
                              :loading="superitendentLoading"
                              class="pa-0"
                              label="Superintendent's Signature"
                              v-model="superintendent"
                              style="width: 100%"
                            />

                            <v-icon
                              v-if="superintendent"
                              style="
                                color: red;
                                cursor: pointer;
                                vertical-align: super;
                              "
                              @click="SRemovedialog = true"
                              >close</v-icon
                            >
                          </v-list-item>

                          <v-list-item>
                            <v-autocomplete
                              :search-input.sync="searchAccountant"
                              :items="accountants"
                              outlined
                              dense
                              hide-no-data
                              label="Accountant's Signature"
                              :loading="accountantLoading"
                              class="pa-0"
                              v-model="accountant"
                              style="width: 100%"
                            />

                            <v-icon
                              v-if="accountant"
                              style="
                                color: red;
                                cursor: pointer;
                                vertical-align: super;
                              "
                              @click="ARemovedialog = true"
                              >close</v-icon
                            >
                          </v-list-item>
                          <v-list-item>
                            <v-checkbox
                              style="margin-right: 20px"
                              color="blue"
                              v-model="allow_back_date_attendance"
                            >
                              <template v-slot:label>
                                <div style="color: #333">
                                  Allow Back Date Attendance
                                  <p style="line-height: 10px">
                                    <small
                                      style="
                                        color: #777;
                                        font-size: 12px;
                                        font-weight: normal;
                                      "
                                    >
                                      Class teacher, administrators will be able
                                      to take admission for previous dates.
                                    </small>
                                  </p>
                                </div>
                              </template>
                            </v-checkbox>
                          </v-list-item>

                          <v-list-item>
                            <v-btn
                              block
                              large
                              color="info outlined"
                              @click="academicSubmit"
                              >Save</v-btn
                            >
                          </v-list-item>
                        </span>

                        <span v-if="item.list === 2">
                          <v-list-item v-if="item.list === 2">
                            <v-select
                              outlined
                              placeholder="Exam"
                              dense
                              :items="ledgerRank"
                              class="pa-0"
                              v-model="ledger_rank"
                            />
                          </v-list-item>

                          <v-list-item>
                            <v-checkbox
                              style="margin-right: 20px"
                              color="blue"
                              v-model="result_block"
                            >
                              <template v-slot:label>
                                <div style="color: #333">
                                  Block Results
                                  <p style="line-height: 10px">
                                    <small
                                      style="
                                        color: #777;
                                        font-size: 12px;
                                        font-weight: normal;
                                      "
                                    >
                                      Student's having their invoice pending is
                                      deprived from results.
                                    </small>
                                  </p>
                                </div>
                              </template>
                            </v-checkbox>
                          </v-list-item>
                          <v-list-item></v-list-item>
                          <v-list-item></v-list-item>
                          <v-list-item></v-list-item>

                          <v-list-item>
                            <v-btn
                              large
                              block
                              color="info outline"
                              @click="examSubmit"
                              >Save</v-btn
                            >
                          </v-list-item>
                        </span>
                        <span v-if="item.list === 3">
                          <br />
                          <v-list-item>
                            <v-select
                              outlined
                              dense
                              label="Select Printer Type"
                              :items="printerTypes"
                              class="pa-0"
                              v-model="printer_type"
                            />
                          </v-list-item>

                          <v-list-item>
                            <v-select
                              multiple
                              outlined
                              dense
                              label="Send SMS On"
                              :items="billing.sms_options"
                              class="pa-0"
                              v-model="billing.sms_selected_options"
                            />
                          </v-list-item>

                          <v-list-item>
                            <v-checkbox
                              style="margin-right: 20px"
                              color="error"
                              v-model="exclude_transportation"
                            >
                              <template v-slot:label>
                                <div style="color: #333">
                                  Exclude Transportation Charge
                                  <p style="line-height: 10px">
                                    <small
                                      style="
                                        color: #777;
                                        font-size: 12px;
                                        font-weight: normal;
                                      "
                                    >
                                      Student's Transportation fee won't show up
                                      in the invoice generation.
                                    </small>
                                  </p>
                                </div>
                              </template>
                            </v-checkbox>
                          </v-list-item>

                          <v-list-item>
                            <v-checkbox
                              style="margin-right: 20px"
                              color="error"
                              v-model="billing.include_pending_dues"
                            >
                              <template v-slot:label>
                                <div style="color: #333">
                                  Include Pending Dues
                                  <p style="line-height: 10px">
                                    <small
                                      style="
                                        color: #777;
                                        font-size: 12px;
                                        font-weight: normal;
                                      "
                                      >Check this if you want to include pending
                                      dues in receipt print
                                    </small>
                                  </p>
                                </div>
                              </template>
                            </v-checkbox>
                          </v-list-item>

                          <v-list-item>
                            <v-checkbox
                              style="margin-right: 20px"
                              color="error"
                              v-model="billing.enable_penalty"
                            >
                              <template v-slot:label>
                                <div style="color: #333">
                                  Enable Penalty
                                  <p style="line-height: 10px">
                                    <small
                                      style="
                                        color: #777;
                                        font-size: 12px;
                                        font-weight: normal;
                                      "
                                      >Check this if you want include penalty on
                                      dues according to your penalty settings.
                                    </small>
                                  </p>
                                </div>
                              </template>
                            </v-checkbox>
                          </v-list-item>

                          <v-list-item></v-list-item>
                          <v-list-item>
                            <v-btn
                              :disabled="billing.saving"
                              :loading="billing.saving"
                              large
                              block
                              color="info outline"
                              @click="billingSubmit"
                              >Save</v-btn
                            >
                          </v-list-item>
                        </span>

                        <span v-if="item.list === 4">
                          <br />
                          <v-list-item>
                            <v-select
                              multiple
                              outlined
                              dense
                              label="Disable Authentication On"
                              :items="
                                authentication.authentication_disable_options
                              "
                              class="pa-0"
                              v-model="authentication.selected_options"
                            />
                          </v-list-item>
                          <v-list-item
                            v-if="
                              authentication.selected_options.includes(
                                'payment-due'
                              )
                            "
                          >
                            <v-select
                              multiple
                              outlined
                              dense
                              label="Fee Head Rule (Block only for Selected Head's Due.)"
                              :items="fee_heads"
                              class="pa-0"
                              v-model="authentication.fee_heads"
                            />
                          </v-list-item>

                          <v-list-item>
                            <v-text-field
                              v-if="
                                authentication.selected_options.includes(
                                  'payment-due'
                                )
                              "
                              outlined
                              dense
                              label="Payment Due Disabled Reason (< 100 character)"
                              placeholder="Will get displayed on authentication failure"
                              maxlength="100"
                              class="pa-0 ma-0"
                              v-model="
                                authentication.disabled_message_for.payment_due
                              "
                            />
                          </v-list-item>

                          <v-list-item>
                            <br />
                            <br />
                            <v-checkbox
                              style="margin-right: 20px"
                              color="error"
                              v-model="authentication.status"
                            >
                              <template v-slot:label>
                                <div style="color: #333">
                                  Authentication Disable Status
                                  <p style="line-height: 10px">
                                    <small
                                      style="
                                        color: #777;
                                        font-size: 12px;
                                        font-weight: normal;
                                      "
                                    >
                                      Check/Uncheck this to Enable/Disable
                                      selected authentication disable methods.
                                    </small>
                                  </p>
                                </div>
                              </template>
                            </v-checkbox>
                          </v-list-item>
                          <v-list-item> </v-list-item>
                          <v-list-item></v-list-item>
                          <v-list-item>
                            <v-btn
                              :disabled="authentication.saving"
                              :loading="authentication.saving"
                              large
                              block
                              color="info outline"
                              @click="authenticationSubmit"
                              >Save</v-btn
                            >
                          </v-list-item>
                        </span>
                      </v-list>
                    </v-card>
                  </v-flex>
                </template>
              </v-layout>
              <!-- </v-container> -->
            </div>
          </v-layout>

          <v-dialog v-model="SRemovedialog" persistent max-width="290">
            <v-card>
              <v-card-title class="title pa-3 red white--text">
                <v-icon class="mr-2 white--text">warning</v-icon>
                Please, wait!
              </v-card-title>
              <v-card-text class="ma-0"
                >Are you sure you want to remove Superitendent Signature?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="success"
                  outlined
                  small
                  @click="SRemovedialog = false"
                  >No
                </v-btn>
                <v-btn color="error" outlined small @click="removeSuperitendent"
                  >Yes</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="ARemovedialog" persistent max-width="290">
            <v-card>
              <v-card-title class="title pa-3 red white--text">
                <v-icon class="mr-2 white--text">warning</v-icon>
                Please, wait!
              </v-card-title>
              <v-card-text class="ma-0"
                >Are you sure you want to remove Account Signature?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="success"
                  outlined
                  small
                  @click="ARemovedialog = false"
                  >No
                </v-btn>
                <v-btn color="error" outlined small @click="removeAccountant"
                  >Yes</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-layout row wrap v-if="model === 'tab-3'">
            <Token></Token>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import Payment from "./../SchoolSetting/components/Payments";
import Token from "./../SchoolSetting/components/Token";
export default {
  components: { Payment, Token },
  data: () => ({
    model: "tab-2",
    switch1: true,
    authentication: {
      authentication_disable_options: [
        { value: "payment-due", text: "Payment Due" },
      ],
      selected_options: [],
      fee_heads: [],
      disabled_message_for: {
        payment_due: "",
      },
      status: false,
      saving: false,
    },
    billing: {
      sms_options: [
        { value: "fee-receipt", text: "Fee Receipt" },
        { value: "fee-general-receipt", text: "Fee General Receipt" },
        { value: "bill-due", text: "Due Report" },
      ],
      sms_selected_options: [],
      include_pending_dues: false,
      enable_penalty: false,
      saving: false,
    },

    allow_attendance_rollback: false,
    result_block: false,
    exclude_transportation: false,
    accountant: "",
    allow_back_date_attendance: false,
    ledger_rank: "",
    marksheet_rank: "",
    bill_generate: "",
    principal: "",
    superintendent: "",
    studentLoading: false,
    exam_coordinator: "",
    search: "",
    searchSuperitendent: null,
    searchAccountant: null,
    searchPrincipal: null,
    searchExamCoordinator: null,
    principalLoading: false,
    superitendentLoading: false,
    accountantLoading: false,
    coordinatorLoading: false,
    SRemovedialog: false,
    ARemovedialog: false,
    accountants: [],
    principals: [],
    superintendents: [],
    coordinators: [],
    ledgerRank: [
      { value: "both", text: "Both" },
      { value: "grade", text: "Grade" },
      { value: "section", text: "Section" },
    ],
    marksheetRank: [
      { value: "grade", text: "Grade" },
      { value: "section", text: "Section" },
    ],
    billingGenerate: [
      { value: "monthly", text: "Monthly" },
      { value: "quarterly", text: "quarterly" },
    ],
    printer_type: "ledger",
    printerTypes: [
      { value: "laser", text: "Laser" },
      { value: "dot-matrix", text: "Dot Matrix" },
    ],
    tabItems: ["Update Log", "Payments", "Settings", "Payment gateway token"],
    items: [
      {
        name: "Academic",
        // calories: 159,
        list: 1,
      },
      {
        name: "Exam",
        // calories: 237,
        list: 2,
      },
      {
        name: "Billing",
        // calories: 262,
        list: 3,
      },
      {
        name: "Authentication",
        // calories: 262,
        list: 4,
      },
    ],
    options: {
      chart: {
        width: "100%",
      },
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
      },
    },
    series: [
      {
        name: "eAcademy Data",
        data: [30, 40, 45, 50, 49, 60, 70, 91],
      },
    ],
    fee_heads: [],
  }),
  computed: {},
  mounted() {
    // this.getSetting();
    this.getExamSetting();
    this.getAuthenticationSetting();
    // this.getBillingSetting();
    this.getAcademicSetting();
    if (this.$route.query.tab === "payments") {
      this.model = "tab-1";
    }
    this.getFeeHead();
  },
  watch: {
    searchPrincipal(val) {
      if (!val) return;
      this.pricipalLoading = true;
      this.coordinatorLoading = false;
      this.accountantLoading = false;
      this.superitendentLoading = false;
      this.$rest
        .get("api/setting-teacher-list?name=" + val + "&role=principal")
        .then(({ data }) => {
          // console.log(data);
          this.principals = data.map((item) => {
            // console.log("item", item);
            return { value: item.id.toString(), text: item.name };
          });
        })
        .catch((err) => {})
        .finally(() => {
          this.studentLoading = false;
          this.pricipalLoading = false;
        });
    },
    searchExamCoordinator(val) {
      console.log("val", val);
      if (!val) return;
      if (this.isLoading) return;
      this.pricipalLoading = false;
      this.coordinatorLoading = true;
      this.superitendentLoading = false;
      this.$rest
        .get("api/setting-teacher-list?name=" + val)
        .then(({ data }) => {
          this.coordinators = data.map((item) => {
            return { value: item.id.toString(), text: item.name };
          });
        })
        .catch((err) => {})
        .finally(() => {
          this.studentLoading = false;
          this.coordinatorLoading = false;
        });
    },
    searchSuperitendent(val) {
      if (!val) return;
      if (this.isLoading) return;

      this.pricipalLoading = false;
      this.coordinatorLoading = false;
      this.accountantLoading = false;
      this.superitendentLoading = true;
      this.$rest
        .get("api/setting-teacher-list?name=" + val)
        .then(({ data }) => {
          this.superintendents = data.map((item) => {
            return { value: item.id.toString(), text: item.name };
          });
        })
        .catch((err) => {})
        .finally(() => {
          this.studentLoading = false;
          this.superitendentLoading = false;
        });
    },
    searchAccountant(val) {
      if (!val) return;
      if (this.isLoading) return;

      this.pricipalLoading = false;
      this.coordinatorLoading = false;
      this.superitendentLoading = false;
      this.accountantLoading = true;
      // if (this.superintendent && this.superintendent.length > 2)
      this.$rest
        .get("api/setting-teacher-list?name=" + val + "&role=accountant")
        .then(({ data }) => {
          this.accountants = data.map((item) => {
            return { value: item.id.toString(), text: item.name };
          });
        })
        .catch((err) => {})
        .finally(() => {
          this.studentLoading = false;
          this.accountantLoading = false;
        });
    },
    "authentication.fee_heads"(value) {
      console.log(value);
    },
  },
  methods: {
    academicSubmit() {
      this.$rest
        .put("/api/setting", {
          allow_attendance_rollback: this.allow_attendance_rollback.toString(),
          allow_back_date_attendance:
            this.allow_back_date_attendance.toString(),
          flag: "academic",
          principal: parseInt(this.principal),
          exam_coordinator: this.exam_coordinator,
          superintendent: this.superintendent,
          accountant: this.accountant,
        })
        .then(({ data }) => {
          this.$events.fire("notification", {
            message: "Setting updated successfully",
            status: "success",
          });
          this.getSetting();
        })
        .finally(() => {
          this.pricipalLoading = false;
        })
        .catch((e) => {
          this.$events.fire("notification", {
            message: e.response.data.message,
            status: "success",
          });
        });
    },
    billingSubmit() {
      this.billing.saving = true;
      this.$rest
        .post("/api/setting", {
          printer_type: this.printer_type,
          exclude_transportation: this.exclude_transportation.toString(),
          sms_on: this.billing.sms_selected_options
            ? this.billing.sms_selected_options.join(",")
            : "",
          include_pending_dues: this.billing.include_pending_dues.toString(),
          enable_penalty: this.billing.enable_penalty.toString(),
          flag: "billing",
        })
        .then(({ data }) => {
          this.$events.fire("notification", { message: data.message });
          this.getSetting();
        })
        .finally(() => {
          this.billing.saving = false;
        });
    },
    authenticationSubmit() {
      this.authentication.saving = true;
      this.$rest
        .post("/api/setting", {
          status: this.authentication.status,
          fee_heads: this.authentication.fee_heads,
          authentication_disable:
            this.authentication.selected_options.join(","),
          disable_message: this.authentication.disabled_message_for,
          flag: "authentication",
        })
        .then(({ data }) => {
          this.$events.fire("notification", { message: data.message });
          this.getSetting();
        })
        .finally(() => {
          this.authentication.saving = false;
        });
    },
    getFeeHead() {
      this.$rest
        .get("/api/fee-head?rowsPerPage=100&withDefaultHeads=true")
        .then(({ data }) => {
          // console.log(data);
          this.fee_heads = data.data.map((item) => {
            return { value: item.title, text: item.title };
          });
        });
    },
    examSubmit() {
      let formData = new FormData();
      formData.set("ledger_rank", this.ledger_rank);
      formData.set("result_block", this.result_block.toString());
      formData.set("marksheet_rank", this.marksheet_rank);
      formData.set("flag", "exam");
      this.$rest.post("/api/setting", formData).then(({ data }) => {
        this.$events.fire("notification", { message: data.message });
        this.getSetting();
      });
    },

    accountSubmit() {},
    // getSetting() {
    //   this.principalLoading = true;
    //   this.coordinatorLoading = true;
    //   this.superitendentLoading = true;
    //   this.accountantLoading = true;
    //   this.$rest
    //     .get("/api/setting")
    //     .then(({ data }) => {
    //       if (data.transit && data.transit.authentication) {
    //         this.authentication.selected_options =
    //           data.transit.authentication.selected_options;
    //         this.authentication.disabled_message_for =
    //           data.transit.authentication.disabled_message_for;
    //         this.authentication.status = data.transit.authentication.status;
    //         this.authentication.fee_heads =
    //           data.transit.authentication.fee_heads;
    //       }

    //       this.allow_attendance_rollback =
    //         data.academic.allowAttendanceRollback || false;
    //       this.allow_back_date_attendance =
    //         data.academic.allowBackdateAttendance || false;
    //       if (data.principal && Object.keys(data.principal).length > 0) {
    //         this.principals = [
    //           { value: data.principal.id, text: data.principal.name },
    //         ];
    //         this.principal = data.principal.id;
    //       }
    //       if (
    //         data.exam_coordinator &&
    //         Object.keys(data.exam_coordinator).length > 0
    //       ) {
    //         this.coordinators = [
    //           {
    //             value: data.exam_coordinator.id,
    //             text: data.exam_coordinator.name,
    //           },
    //         ];
    //         this.exam_coordinator = data.exam_coordinator.id;
    //       }
    //       if (
    //         data.superintendent &&
    //         Object.keys(data.superintendent).length > 0
    //       ) {
    //         this.superintendents = [
    //           { value: data.superintendent.id, text: data.superintendent.name },
    //         ];
    //         this.superintendent = data.superintendent.id;
    //       }

    //       if (data.accountant && Object.keys(data.accountant).length > 0) {
    //         this.accountants = [
    //           { value: data.accountant.id, text: data.accountant.name },
    //         ];
    //         this.accountant = data.accountant.id;
    //       }

    //       //exam
    //       this.marksheet_rank = data.exam ? data.exam.marksheet_rank : "";
    //       this.ledger_rank = data.exam ? data.exam.ledger_rank : "";

    //       billing
    //       this.printer_type = data.billing ? data.billing.printer_type : "";
    //       this.result_block = data.exam.result_block || false;
    //       this.exclude_transportation =
    //         data.billing.exclude_transportation === "true";
    //       this.billing.sms_selected_options = data.billing.sms_on;
    //       this.billing.include_pending_dues =
    //         data.billing.include_pending_dues === "true";
    //       this.billing.enable_penalty = data.billing.enable_penalty === "true";
    //     })
    //     .finally(() => {
    //       this.principalLoading = false;
    //       this.coordinatorLoading = false;
    //       this.superitendentLoading = false;
    //       this.accountantLoading = false;
    //     });
    // },
    getExamSetting() {
      this.$rest.get("/api/exam-setting").then(({ data }) => {
        this.marksheet_rank = data.exam ? data.exam.marksheet_rank : "";
        this.ledger_rank = data.exam ? data.exam.ledger_rank : "";
      });
    },
    getAuthenticationSetting() {
      this.$rest.get("/api/authentication-setting").then(({ data }) => {
        if (data.transit && data.transit.authentication) {
          this.authentication.selected_options =
            data.transit.authentication.selected_options;
          this.authentication.disabled_message_for =
            data.transit.authentication.disabled_message_for;
          this.authentication.status = data.transit.authentication.status;
          this.authentication.fee_heads = data.transit.authentication.fee_heads;
        }
      });
    },
    getBillingSetting() {
      this.$rest.get("/api/billing-setting").then(({ data }) => {
        this.printer_type = data.billing ? data.billing.printer_type : "";
        this.result_block = data.exam.result_block || false;
        this.exclude_transportation =
          data.billing.exclude_transportation === "true";
        this.billing.sms_selected_options = data.billing.sms_on;
        this.billing.include_pending_dues =
          data.billing.include_pending_dues === "true";
        this.billing.enable_penalty = data.billing.enable_penalty === "true";
      });
    },
    getAcademicSetting() {
      this.principalLoading = true;
      this.coordinatorLoading = true;
      this.superitendentLoading = true;
      this.accountantLoading = true;
      this.$rest
        .get("/api/academic-setting")
        .then(({ data }) => {
          this.allow_attendance_rollback =
            data.academic.allowAttendanceRollback || false;
          this.allow_back_date_attendance =
            data.academic.allowBackdateAttendance || false;
          //Principle
          if (
            (data,
            data.signature.principal && data.signature.principal.length > 0)
          ) {
            this.principals = [
              {
                value: data.academic.academic.signature.principal,
                text: data.signature.principal,
              },
            ];
            this.principal = data.academic.academic.signature.principal;
          }
          //Exam Coordinator
          console.log("Exam Coordinator");
          if (
            data.signature.exam_coordinator &&
            data.signature.exam_coordinator.length > 0
          ) {
            // console.log("if");
            this.coordinators = [
              {
                value: data.academic.academic.signature.exam_coordinator,
                text: data.signature.exam_coordinator,
              },
            ];
            this.exam_coordinator =
              data.academic.academic.signature.exam_coordinator;
          }

          if (
            data.signature.superintendent &&
            data.signature.superintendent.length > 0
          ) {
            console.log(
              data.academic.academic.signature.superintendent,
              "super"
            );
            this.superintendents = [
              {
                value: data.academic.academic.signature.superintendent,
                text: data.signature.superintendent,
              },
            ];
            this.superintendent =
              data.academic.academic.signature.superintendent;
          }
          if (
            data.signature.accountant &&
            data.signature.accountant.length > 0
          ) {
            this.accountants = [
              {
                value: data.academic.academic.signature.accountant,
                text: data.signature.accountant,
              },
            ];
            this.accountant = data.academic.academic.signature.accountant;
          }
        })
        .finally(() => {
          this.principalLoading = false;
          this.coordinatorLoading = false;
          this.superitendentLoading = false;
          this.accountantLoading = false;
        });
    },
    removeSuperitendent() {
      this.superintendent = "";
      this.academicSubmit();
      this.SRemovedialog = false;
    },
    removeAccountant() {
      this.accountant = "";
      this.academicSubmit();
      this.ARemovedialog = false;
    },
  },
  beforeDestroy() {
    this.$events.remove("notification");
  },
};
</script>
<style lang="scss">
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  margin-top: 20px;
}
.v-tabs__item {
  color: #888;
}

.switchButton {
  margin-bottom: 18px;
}

.v-tabs__item--active {
  color: #1976d2 !important;
  font-weight: bold;
}

.info {
  background-color: #2196f3 !important;
  border-color: #2196f3 !important;
}

.setting-container {
  display: flex;
  flex-direction: column;

  .v-list--dense {
    flex: 1;

    span {
      width: 100%;
      display: flex;
      flex: 1;
      height: 100%;
      flex-direction: column;
    }

    div[role="listitem"]:last-child {
      margin-top: auto;
    }

    /*background-color: red;*/
  }
}

.dash-container {
  min-height: 100px;
  width: 90%;
  /*padding: 40px 0 0 0;*/
  margin: 0 auto;

  div.dash-company-info {
    padding-top: 40px;
    display: flex;
    padding-bottom: 10px;
  }

  h1 {
    color: #555;
    font-weight: 300;

    + p {
      color: #888;
      font-size: 14px;
    }
  }

  .navigate-to {
    flex: 1;
    padding-top: 15px;

    ul li {
      list-style: none;
      display: inline;

      a {
        display: inline-block;
        border: 0.15em solid #c9c9c9;
        margin-left: 10px;
        height: 35px;
        width: 35px;
        text-align: center;
        line-height: 30px;
        border-radius: 50px;
        text-decoration: none;

        &:hover {
          i {
            color: #333 !important;
          }
        }
      }
    }
  }

  .intro {
    flex: 3;
  }

  .submitButton {
    width: 108%;
    background: blue;
    margin-left: -15px;
    margin-top: 6px;
    color: black;
    height: 40px;
  }
}

.update-item {
  margin-bottom: 20px;

  .caption {
    color: #999;

    strong {
      color: #777;
    }
  }
}
</style>
